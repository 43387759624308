<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main2'
import i18n from '@/i18n'
/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง',
    meta: [{ name: 'description' }],
  },
  components: { Layout },
  data() {
    // let lang = localStorage.getItem('account_lang')
    // let user = localStorage.getItem('users')
    // var lang_obj = i18n.t('plans_display')
    return {
      //genaral
      account_lang: lang,
      account_data: user,
      // lang: lang_obj,
    }
  },
  watch: {},
  mounted() {

  },
  methods: {

  },
}
</script>

<template>
  <Layout>
    <!--  -->
  </Layout>
</template>
<style>

</style>
